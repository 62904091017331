export const environment = {
  production: false,
  baseApiUrl3: "https://crmreferralapi.universityhub.com/api/",
  twilioApiUrl: "https://crmtwilioapi.universityhub.com/api/",
  SMSapiurl: "https://crmtwilioapi.universityhub.com/api/",
  socketUrl: "https://crmtwilioapi.universityhub.com/",
  socketUrl2: "https://crmservicesapi.universityhub.com/",
  baseApiUrl: "https://crmservicesapi.universityhub.com/api/v1/",
  baseApiUrl2: "https://crmservicesapi.universityhub.com/api/v1/",
  baseApiUrl2Test: "https://crmtest.universityhub.com/api/v1/",
  // baseApiUrl: "http://localhost:4000/api/v1/",
  // baseApiUrl2: "http://localhost:4000/api/v1/",
  webAppUrl:'https://app.universityhub.com/studentUniversity/',
  baseCuNextUrl: "https://cunextapi.campbellsville.edu/api/cunext/v1/",
  forwardMail: "@accessque.com",
  fileServer: "services",
  firebase: {
    apiKey: 'AIzaSyDXd86FG97SodBdz5W7BEh6z_apAdg86OM',
    authDomain: 'accessque-f4edb.firebaseapp.com',
    projectId: 'accessque-f4edb',
    storageBucket: 'accessque-f4edb.appspot.com',
    messagingSenderId: '991318304901',
    appId: '1:991318304901:web:c713749910d96d50daa1ec',
    measurementId: 'G-Z4Z5FYRBWB',
    vapidKey: 'BHN6imBr9X7fZxuXouWfk5DS3eQOi0oaMluvZ6l0AdCjOiq2fbkkFdzfq7lXAiSR1egqbY9MNH6ggN8lqPN_eEM'
  },
  BaseURL : 'https://crm.universityhub.com/',
  TwilioNumber:'+16062206004',
  s3URL: 'https://accessque.fra1.digitaloceanspaces.com/',
  uploadContactCSV: 'https://accessque.fra1.digitaloceanspaces.com/TemplateDocuments/CampaignContacts.xlsx',
  referralCSV: "https://accessque.fra1.digitaloceanspaces.com/TestServer/TemplateDocuments/RefereeUpload.xlsx",
  referreeCSV: "https://accessque.fra1.digitaloceanspaces.com/TestServer/TemplateDocuments/RefereeUpload.xlsx",
  countryCodesURL: 'https://countriesnow.space/api/v0.1/countries/codes',
  Judsonuleads: 'Judsonu',
  Judsonucsvfile: 'judsonu.xlsx',
  Uwlauleads: 'UWLA',
  Sfcleads: 'SFC',
  Sfconucsvfile: 'sfc.xlsx',
  Sfc_Application_Type: 'Y',
  Sfc_Program_Type: 'MS',
  sfcURL: 'https://sfctestapi.universityhub.com/api/sfc/v1/',  
  url_v1: "https://crmsfcapi.universityhub.com/api/sfc/v1/",
  forgotPassEmailTempId: 9,
  companyId: 14,
  whatsApp_Sender: '+917337368804',
  whatsApp_Sender_projectId: 0,
  Uwlaonucsvfile: 'uwla.xlsx',
  UwlaLeads: 'UWLA',
  freJunURL:"https://api.frejun.com/api/v1/integrations/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
